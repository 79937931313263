import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Notification/Notification.module.scss';

const Notification = ({ children, title, className, icon, type }) => (
  <div
    className={classNames(
      className,
      styles.container,
      styles[type],
      'd-flex align-content-center p-2',
    )}
  >
    <div className={classNames('font-weight-bold', styles.title)}>
      <span className={classNames('icon mr-2', icon, styles.icon)} />
      <span>{title}</span>
    </div>
    {children && <div className={styles.content}>{children}</div>}
  </div>
);

Notification.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

Notification.defaultProps = {
  className: '',
  children: null,
  icon: '',
  title: '',
  type: 'success',
};

export default Notification;
