import React, { useRef, useLayoutEffect, useState, Fragment } from 'react';
import Prism from 'prismjs';
import classNames from 'classnames';

import 'assets/styles/app.scss';

import Card from '@moonshineragency/ui/src/components/Card/Card';
import Badge, {
  RemovableBadge,
} from '@moonshineragency/ui/src/components/Badge/Badge';
import Button, {
  IconButton,
  LinkButton,
} from '@moonshineragency/ui/src/components/Button/Button';
import Header from '@moonshineragency/ui/src/components/Header/Header';
import Form, {
  Field,
  FieldGroup,
  CheckboxGroup,
  RadioGroup,
  FormNavigation,
} from '@moonshineragency/ui/src/components/Forms/Forms';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Navigation from '@moonshineragency/ui/src/components/Navigation/Navigation';
import Notification from '@moonshineragency/ui/src/components/Notification/Notification';
import Popout from '@moonshineragency/ui/src/components/Popout/Popout';
import Steps, { Step } from '@moonshineragency/ui/src/components/Steps/Steps';
import TabContainer, {
  Tab,
} from '@moonshineragency/ui/src/components/Tabs/Tabs';
import Table, {
  TableRow as TR,
  TableData as TD,
} from '@moonshineragency/ui/src/components/Table/Table';

import Container from 'components/Container/Container';
import Footer from 'components/Footer/Footer';

import styles from 'pages/Styleguide.module.scss';
import 'pages/prism.css';

import logo from 'images/evn-logo.png';

const TH = props => <TD type="th" {...props} />;

function format(html) {
  const tab = '\t';
  let result = '';
  let indent = '';
  let closeInput = false;

  html.split(/>\s*</).forEach(element => {
    if (element.match(/^\/\w/) || closeInput) {
      indent = indent.substring(tab.length);
    }

    result += `${indent}<${element}>\r\n`;

    if (element.match(/^<?\w[^>]*[^/]$/)) {
      indent += tab;
    }

    closeInput = false;

    // input elements have no closing / so the next element after an input needs to decrease the indent
    if (element.match(/^input/)) {
      closeInput = true;
    }
  });

  return result.substring(1, result.length - 3);
}
const Editor = ({ children, title, className, id }) => {
  const textInput = useRef(null);
  const [element, setElementHtml] = useState(null);
  const [isCodeShown, toggleCode] = useState(false);
  useLayoutEffect(() => {}, []);
  const showCode = e => {
    e.preventDefault();
    toggleCode(prev => !prev);
    setElementHtml(format(textInput.current.innerHTML));
    Prism.highlightAll();
    setTimeout(() => Prism.highlightAll(), 0);
  };
  return (
    <div id={id} className={className}>
      <h2>{title}</h2>
      <div>
        <div className="mb-4" ref={textInput}>
          {children}
        </div>
        <a onClick={showCode} href="/#">
          Show code
        </a>
        {isCodeShown && (
          <pre className="line-numbers">
            <code className="language-html">{element}</code>
          </pre>
        )}
      </div>
    </div>
  );
};

const availableLinks = [
  'header',
  'button',
  'card',
  'badge',
  'steps',
  'footer',
  'forms',
  'navigation',
  'notification',
  'typography',
  'table',
  'popout',
  'tabs',
]
  .sort()
  .concat('pages');

availableLinks.unshift('colors');

const renderTableOfContents = links => (
  <ul className="mt-2">
    {links.map(l => (
      <a key={l} className="text-capitalize d-block p-1" href={`#${l}`}>
        {l}
      </a>
    ))}
  </ul>
);

const pillOptions = [
  {
    name: 'sonne',
    label: 'Sonne',
  },
  {
    name: 'wasser',
    label: 'Wasser',
  },
  {
    name: 'Wind',
    label: 'Wind',
  },
  {
    name: 'Batterie',
    label: 'Batterie',
  },
  {
    name: 'Klärgas',
    label: 'Klärgas',
  },
  {
    name: 'Deponiegas',
    label: 'Deponiegas',
  },
  {
    name: 'Rest-/Abfallstoffe',
    label: 'Rest-/Abfallstoffe',
  },
  {
    name: 'Sonstige',
    label: 'Sonstige',
  },
];

const renderPages = (type, n) => {
  const basePath = `/${type}/`;
  const arr = new Array(n).fill(0);
  const pages = ['index', ...arr.map((p, i) => `example${i + 1}`)];

  return (
    <Card title={`Example pages for ${type}`} className="mb-4">
      {pages.map(p => (
        <Fragment key={`page-${p}`}>
          <Link
            className="mr-2"
            to={`${p === 'index' ? basePath : basePath + p}`}
          >
            {p.toUpperCase()}
          </Link>
          <br />
        </Fragment>
      ))}
    </Card>
  );
};

const IndexPage = () => {
  return (
    <div className="d-flex overflow-hidden">
      <div className={styles.sidebarWrapper}>
        <div className={styles.sidebar}>
          <div className={styles.header}>
            <img className={styles.image} src={logo} alt="logo" />
            <h1 className="text-center mt-4 ml-3 text-nowrap text-truncate">
              Styleguide
            </h1>
          </div>
          <div className={styles.tableOfContents}>
            {renderTableOfContents(availableLinks)}
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <Container>
          {/* Colors */}
          <div className="mt-3">
            <h2>Colors</h2>
            <Card id="colors" title="Accent">
              <div className="d-flex flex-wrap">
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.primary,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Primary #E0001B
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.secondary,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Secondary #8c8c8c
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.blue,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Blue #0951b0
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.red100,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Red 100 #E0929B
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.white,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  White #FFFFFF
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.red400,
                    'mr-2 mb-2 pl-3 pr-3 pb-3 pt-2 font-weight-bold flex-wrap',
                  )}
                >
                  <div className={classNames(styles.forceWrap, 'text-center')}>
                    Red 400 #D22626
                  </div>
                  <small
                    className={classNames(
                      styles.colorDescription,
                      'text-truncate',
                    )}
                  >
                    Only used for errors
                  </small>
                </div>
              </div>
            </Card>
            <Card title="Grays">
              <div className="d-flex flex-wrap">
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.gray25,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Gray 25 #f6f6f6
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.gray50,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Gray 50 #eee
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.gray75,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Gray 75 #b3b3b3
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.gray100,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Gray 100 #8c8c8c
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.gray200,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Gray 200 #666
                </div>
                <div
                  className={classNames(
                    styles.colorWrapper,
                    styles.gray300,
                    'mr-2 mb-2 p-3 font-weight-bold',
                  )}
                >
                  Gray 300 #333
                </div>
              </div>
            </Card>
          </div>

          {/** Badge */}
          <div>
            <Editor id="badge" className="my-3" title="Badge">
              <Card>
                <Badge theme="primary" className="font-weight-bold w-25 mb-2">
                  Badge Primary
                </Badge>
                <Badge theme="secondary" className="font-weight-bold w-25 mb-2">
                  Badge Secondary
                </Badge>
                <Badge theme="blue" className="font-weight-bold w-25">
                  Badge Blue
                </Badge>
              </Card>
            </Editor>
            <Editor>
              <Card>
                <RemovableBadge className="w-20">
                  Removable Badge
                </RemovableBadge>
              </Card>
            </Editor>
          </div>

          {/* Buttons */}
          <div>
            <Editor
              id="button"
              className={classNames('my-3', 'overflow-auto')}
              title="Button"
            >
              <div className="overflow-auto bg-white">
                <Card title="States">
                  <div className="d-flex">
                    <Button className="mr-2" theme="primary">
                      Primary
                    </Button>
                    <Button className="mr-2" theme="secondary">
                      Secondary
                    </Button>
                    <Button disabled theme="primary">
                      Disabled
                    </Button>
                  </div>
                </Card>
                <Card title="Sizes">
                  <div className="d-flex">
                    <Button size="50" className="mr-2" theme="primary">
                      Size 50
                    </Button>
                    <Button size="100" className="mr-2" theme="primary">
                      Size 100
                    </Button>
                    <Button size="200" className="mr-2" theme="primary">
                      Size 200
                    </Button>
                  </div>
                </Card>
                <Card title="Icon">
                  <div className="d-flex">
                    <IconButton className="mr-2" theme="primary">
                      <span className="icon user mr-2" />
                      Primary with label
                    </IconButton>
                    <IconButton className="mr-2" theme="secondary">
                      <span className="icon user mr-2" />
                      Secondary with label
                    </IconButton>
                    <IconButton className="mr-2" small theme="primary">
                      <span className="icon user" />
                    </IconButton>
                    <IconButton className="mr-2" round small theme="secondary">
                      <span className="icon user" />
                    </IconButton>
                  </div>
                </Card>
                <Card title="Ghost">
                  <div className="d-flex">
                    <LinkButton className="mr-2" theme="primary">
                      Primary Ghost
                    </LinkButton>
                    <LinkButton theme="secondary">Secondary Ghost</LinkButton>
                  </div>
                </Card>
              </div>
            </Editor>
          </div>

          {/* Card */}
          <div>
            <Editor id="card" className="my-3" title="Card">
              <Card
                title="Card Title"
                img={<img className={styles.cardImage} src={logo} alt="logo" />}
              >
                <div>Child Element 1</div>
                <div>Child Element 2</div>
              </Card>
            </Editor>
          </div>

          {/* Header */}
          <div>
            <Editor id="header" className="my-3" title="Header">
              <Card>
                <Header img={logo} title="Header Text" />
              </Card>
              <Card>
                <Header img={logo} title="Header Text" renderHeaderNav />
              </Card>
            </Editor>
          </div>

          {/* Forms */}
          <div>
            <Editor id="forms" className="my-3" title="Forms">
              <Form>
                {/* Text inputs */}

                <Card className="mb-4" title="Text inputs">
                  <div className="mb-2">
                    <Field name="vorname" type="text" label="Text-Input" />
                  </div>
                  <div className="mb-2">
                    <Field
                      name="vorname"
                      error
                      type="text"
                      label="Text-Input mit Fehler"
                    />
                  </div>
                  <div>
                    <Field
                      name="informationen"
                      as="textarea"
                      placeholder="optional"
                      label="Textarea"
                    />
                  </div>
                </Card>
              </Form>
            </Editor>

            {/* Field group */}
            <Editor className="my-3">
              <Form>
                <Card className="mb-4" title="Field Group">
                  <FieldGroup
                    label="Blindleistungskapazität bei Nennleistung"
                    name="blindleistungskapazität"
                    items={[
                      {
                        name: 'induktiv',
                        hint: 'cos() induktiv',
                        className: 'mr-2 flex-grow-1',
                      },
                      {
                        name: 'kapazitiv',
                        hint: 'cos() kapazitiv',
                        className: 'flex-grow-1',
                      },
                    ]}
                  />
                </Card>
              </Form>
            </Editor>
            <Editor className="my-3">
              <Form>
                <Card title="Field Group with children">
                  <FieldGroup
                    name="leistung"
                    items={[
                      {
                        label: 'Nennscheinleistung',
                        name: 'nennscheinleistung',
                        className: 'mr-2 flex-grow-1',
                        children: <span className="ml-1">kVA</span>,
                      },
                      {
                        label: 'Max Wirkleistung',
                        name: 'max wirkleistung',
                        className: 'mr-2 flex-grow-1',
                        children: <span className="ml-1">kW</span>,
                      },
                      {
                        label: 'Modulleistung',
                        name: 'modulleistung',
                        className: 'flex-grow-1',
                        children: <span className="ml-1">kWp</span>,
                      },
                    ]}
                  />
                </Card>
              </Form>
            </Editor>

            {/* Checkbox pills */}
            <Editor className="my-3">
              <Form>
                <Card className="mb-4" title="Pill group">
                  <div>
                    <CheckboxGroup
                      type="pill"
                      label="Gewünschtes Produkt"
                      items={pillOptions}
                      name="typePill"
                    />
                  </div>
                </Card>
              </Form>
            </Editor>
            {/* Checkbox group */}
            <Editor className="my-3">
              <Form>
                <Card className="mb-4" title="Checkbox group block">
                  <div>
                    <CheckboxGroup
                      labelClassName="font-weight-normal"
                      label="Gewünschtes Produkt"
                      items={pillOptions}
                      name="type"
                    />
                  </div>
                </Card>
              </Form>
            </Editor>
            <Editor className="my-3">
              <Form>
                <Card className="mb-4" title="Checkbox group inline">
                  <div>
                    <CheckboxGroup
                      inline
                      labelClassName="font-weight-normal"
                      label="Gewünschtes Produkt"
                      items={pillOptions}
                      name="type"
                    />
                  </div>
                </Card>
              </Form>
            </Editor>

            {/* Radio group */}
            <Editor className="my-3">
              <Form>
                <Card className="mb-4" title="Radio group block">
                  <div>
                    <RadioGroup
                      labelClassName="font-weight-normal"
                      label="Art der Einspeisung"
                      items={[
                        {
                          name: 'small',
                          label: (
                            <>
                              <strong>
                                Volleinspeisung (100% Einspeisung)
                              </strong>
                              <div>Beschreibungstext</div>
                            </>
                          ),
                        },
                        {
                          name: 'large',
                          label: (
                            <>
                              <strong>
                                Überschusseinspeisung (teilweiser
                                Eigenverbrauch)
                              </strong>
                              <div>Weiterer Beschreibungstext</div>
                            </>
                          ),
                        },
                        {
                          name: 'diff1',
                          label: 'Weiterer Titel',
                        },
                        {
                          name: 'diff2',
                          label: <strong>Weiterer Titel</strong>,
                        },
                      ]}
                      name="usage"
                    />
                  </div>
                </Card>
              </Form>
            </Editor>
            <Editor className="my-3">
              <Form>
                <Card className="mb-4" title="Radio group inline">
                  <div>
                    <RadioGroup
                      inline
                      labelClassName="font-weight-normal"
                      label="Art der Einspeisung"
                      items={[
                        {
                          name: 'small2',
                          label: (
                            <>
                              <strong>
                                Volleinspeisung (100% Einspeisung)
                              </strong>
                              <div>Beschreibungstext</div>
                            </>
                          ),
                        },
                        {
                          name: 'large2',
                          label: (
                            <>
                              <strong>
                                Überschusseinspeisung (teilweiser
                                Eigenverbrauch)
                              </strong>
                              <div>Weiterer Beschreibungstext</div>
                            </>
                          ),
                        },
                      ]}
                      name="usage2"
                    />
                  </div>
                </Card>
              </Form>
            </Editor>
            <Editor className="my-3">
              <Form>
                <Card className="mb-4" title="Form navigation">
                  {/* Form navigation */}
                  <FormNavigation />
                </Card>
              </Form>
            </Editor>
          </div>

          {/* Footer */}
          <Editor id="footer" className="my-3" title="Footer">
            <Card>
              <Footer
                renderStyleguide={false}
                items={[
                  { title: 'Impressum', link: '/impressum' },
                  { title: 'Kontakt', link: '/kontakt' },
                  { title: 'Datenschutz', link: '/datenschutz' },
                ]}
              />
            </Card>
          </Editor>

          {/* Navigation */}
          <div>
            <Editor id="navigation" className="my-3" title="Navigation">
              <Card className="mb-4">
                <Navigation />
              </Card>
            </Editor>
          </div>

          {/* Notification */}
          <div>
            <Editor id="notification" className="my-3" title="Notification">
              <Card className="mb-4">
                <Notification
                  className="mb-2"
                  type="success"
                  title="Erfolgreiche Aktion"
                  icon="smile"
                >
                  Die technischen Anschlussdaten wurden Ihrem Antrag erfolgreich
                  hinzugefügt.
                </Notification>
                <Notification
                  type="error"
                  title="Fehlerhafte Aktion"
                  icon="confused"
                >
                  Es ist ein Fehler aufgetreten.
                </Notification>
              </Card>
            </Editor>
          </div>

          {/* Popout */}
          <div>
            <Editor id="popout" className="my-3" title="Popout">
              <Card className="mb-4">
                <Popout className="mb-4" arrowPosition="left">
                  Content
                </Popout>
                <Popout className="mb-4" arrowPosition="center">
                  Content
                </Popout>
                <Popout arrowPosition="right">Content</Popout>
              </Card>
            </Editor>
          </div>

          {/* Steps */}
          <div>
            <Editor id="steps" className="my-3" title="Steps">
              <Card title="Horizontal">
                <Steps
                  className="p-3"
                  active="location"
                  orientation="horizontal"
                >
                  <Step id="customer" title="Kunde" icon="user" />
                  <Step id="location" title="Standort" icon="home" />
                  <Step id="connection" title="Anschluss" icon="units" />
                  <Step id="electrician" title="Elektriker" icon="wrench" />
                  <Step id="check" title="Prüfen" icon="check" />
                </Steps>
              </Card>
            </Editor>
            <Editor className="my-3">
              <Card title="Vertical">
                <Steps className="p-3" active="location" orientation="vertical">
                  <Step id="customer" title="Kunde" icon="user" />
                  <Step id="location" title="Standort" icon="home" />
                  <Step id="connection" title="Anschluss" icon="units" />
                  <Step id="electrician" title="Elektriker" icon="wrench" />
                  <Step id="check" title="Prüfen" icon="check" />
                </Steps>
              </Card>
            </Editor>
            <Editor className="my-3">
              <Card title="Vertical with description text">
                <Steps
                  className="align-items-start p-3"
                  active="electrician"
                  orientation="vertical"
                >
                  <Step id="customer" title="Kunde und Anschluss" icon="home">
                    <div className="mb-3 justify-self-start">
                      <h5 className="m-0 text-reset">Kunde</h5>
                      <div>Doris Baumann</div>
                    </div>
                    <div className="mb-3">
                      <h5 className="m-0 text-reset">
                        Standort des Anschlusses
                      </h5>
                      <div>Teichmanns 2</div>
                      <div>23623 Kottes-Purk</div>
                    </div>
                    <div className="mb-3">
                      <h5 className="m-0 text-reset">
                        Zuständiger Mitarbeiter
                      </h5>
                      <div>Markus Michaelovic</div>
                      <div>0664/1243213</div>
                    </div>
                  </Step>
                  <Step
                    id="electrician"
                    title="Technische Details"
                    icon="wrench"
                  />
                  <Step id="check" title="Auftrag prüfen" icon="check" />
                </Steps>
              </Card>
            </Editor>
            <Editor className="my-3">
              <Card title="Vertical with description text active">
                <Steps
                  className="align-items-start p-3"
                  active="customer"
                  orientation="vertical"
                >
                  <Step id="customer" title="Kunde und Anschluss" icon="home">
                    <div className="mb-3 justify-self-start">
                      <h5 className="m-0 text-reset">Kunde</h5>
                      <div>Doris Baumann</div>
                    </div>
                    <div className="mb-3">
                      <h5 className="m-0 text-reset">
                        Standort des Anschlusses
                      </h5>
                      <div>Teichmanns 2</div>
                      <div>23623 Kottes-Purk</div>
                    </div>
                    <div className="mb-3">
                      <h5 className="m-0 text-reset">
                        Zuständiger Mitarbeiter
                      </h5>
                      <div>Markus Michaelovic</div>
                      <div>0664/1243213</div>
                    </div>
                  </Step>
                  <Step
                    id="electrician"
                    title="Technische Details"
                    icon="wrench"
                  />
                  <Step id="check" title="Auftrag prüfen" icon="check" />
                </Steps>
              </Card>
            </Editor>
          </div>

          {/* Table */}
          <div>
            <Editor id="table" className="my-3" title="Table">
              <Card>
                <div className="table-responsive">
                  <Table className="text-nowrap mb-0">
                    <thead className="thead-light">
                      <TR>
                        <TH />
                        <TH>Nummer</TH>
                        <TH>Geändert</TH>
                        <TH>Kunde</TH>
                        <TH>Anschlussadresse</TH>
                        <TH>Auftrag</TH>
                      </TR>
                    </thead>
                    <tbody>
                      <TR>
                        <TD className="d-flex justify-content-center">
                          <Badge theme="blue" className="font-weight-bold">
                            Neu
                          </Badge>
                        </TD>
                        <TD>DW-10912234</TD>
                        <TD>07.12.2019</TD>
                        <TD>Beatrix Herzmanonn</TD>
                        <TD>Teichmanns 2, 3623 Korneuburg</TD>
                        <TD>Neuanschluss</TD>
                      </TR>
                      <TR>
                        <TD />
                        <TD>DW-54534434</TD>
                        <TD>10.11.2019</TD>
                        <TD>Karl Bauer-Eder</TD>
                        <TD>Mauerbachstraße 24, 3623 Korneuburg</TD>
                        <TD>Neuanschluss</TD>
                      </TR>
                      <TR>
                        <TD />
                        <TD>DW-76787877</TD>
                        <TD>24.09.2019</TD>
                        <TD>Herbert Weber</TD>
                        <TD>Hofweg 15, 2400 Baden</TD>
                        <TD>Neuanschluss</TD>
                      </TR>
                      <TR>
                        <TD className="d-flex justify-content-center">
                          <Badge theme="secondary" className="font-weight-bold">
                            Erledigt
                          </Badge>
                        </TD>
                        <TD>DW-88976555</TD>
                        <TD>07.08.2019</TD>
                        <TD>Fred Schreyvogel</TD>
                        <TD>Einbahngasse 24, 2340 Baden</TD>
                        <TD>Erzeugungsanlage</TD>
                      </TR>
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Editor>
          </div>

          {/** Tabs */}
          <Editor id="tabs" className="my-3" title="Tabs">
            <div>
              <Card>
                <TabContainer>
                  <Tab>Tab 1</Tab>
                  <Tab>Tab 2</Tab>
                  <Tab active>Tab 3</Tab>
                  <Tab>Tab 4</Tab>
                </TabContainer>
              </Card>
            </div>
          </Editor>

          {/** Typography */}
          <Editor id="typography" className="my-3" title="Typography">
            <Card title="Headlines">
              <h1>H1 Headline</h1>
              <h2>H2 Headline</h2>
              <h3>H3 Headline</h3>
              <h4>H4 Headline</h4>
              <h5>H5 Headline</h5>
            </Card>
          </Editor>
          <Editor className="my-3">
            <Card title="Paragpraph">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                iaculis consequat mi porttitor pulvinar. Ut consectetur lacinia
                felis nec posuere. Morbi volutpat nisl justo, a ullamcorper leo
                maximus sed. Nam et lobortis eros. Nam et porta ex, eget
                ultrices dui. Sed tempus pulvinar augue, et interdum massa
                tristique ac. Curabitur ullamcorper consectetur ligula et
                suscipit. In porta quam vel est imperdiet, a dictum lectus
                pharetra. Vestibulum vitae ornare mauris, a aliquet diam.
                Aliquam ac nisl aliquet, semper erat eu, vulputate metus. Nullam
                lacinia eros ac efficitur scelerisque. Morbi id posuere ligula.
                Pellentesque ipsum felis, viverra et ligula vitae, fringilla
                scelerisque lectus. Nunc aliquet nulla at leo ornare, vitae
                tincidunt lacus cursus. Vivamus sagittis, mauris sit amet
                malesuada fermentum, velit nibh sodales eros, vitae rhoncus
                lectus leo eu eros. Aliquam erat volutpat.
              </p>
            </Card>
          </Editor>
          <Editor className="my-3">
            <Card title="Bold">
              <strong>Bold text</strong>
            </Card>
          </Editor>
          <Editor className="my-3">
            <Card title="Link">
              <a href="/#">Example link</a>
            </Card>
          </Editor>
          <Editor className="my-3">
            <Card title="Ordered list">
              <ol>
                <li>List element</li>
                <li>List element</li>
                <li>List element</li>
                <li>List element</li>
                <li>List element</li>
              </ol>
            </Card>
          </Editor>
          <Editor className="my-3">
            <Card title="Unordered list">
              <ul>
                <li>List element</li>
                <li>List element</li>
                <li>List element</li>
                <li>List element</li>
                <li>List element</li>
              </ul>
            </Card>
          </Editor>
          <Editor className="my-3">
            <Card title="Small">
              <small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </small>
            </Card>
          </Editor>

          {/** Pages */}
          <hr />
          <h2 id="pages">Pages</h2>
          <p>Example pages</p>
          <div>{renderPages('consumer', 2)}</div>
          <div>{renderPages('electrician', 1)}</div>
        </Container>
      </div>
    </div>
  );
};

export default IndexPage;
