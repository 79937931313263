import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '@moonshineragency/ui/src/components/Tabs/Tabs.module.scss';

const Tab = ({ className, children, active }) => (
  <div
    className={classNames(
      styles.tab,
      className,
      active && styles.active,
      'font-weight-bold p-2 text-center',
    )}
  >
    {children}
  </div>
);

const TabContainer = ({ children, className }) => (
  <div className={classNames(className, styles.tabContainer, 'd-flex')}>
    {children}
  </div>
);

TabContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

TabContainer.defaultProps = {
  className: '',
  children: null,
};

Tab.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

Tab.defaultProps = {
  active: false,
  className: '',
  children: null,
};

export { TabContainer as default, Tab };
